<template>
    <div>
        <input hidden type="text" class="form-control" id="PurchasingInvoiceId" v-model="PurchasingInvoiceId">
        <div class="modal fade" id="PurchasingInvoiceStatusModal" tabindex="-1" aria-labelledby="PurchasingInvoiceStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PurchasingInvoiceStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{PurchasingInvoiceNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                      
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import purchasingInvoiceServices from '../Script/PurcashingInvoiceServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurchasingInvoiceStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            PurchasingInvoiceId : '',
            PurchasingInvoiceNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(invData){
            this.ModalTitle = 'Edit Status Purchasing Invoice: ';
            this.PurchasingInvoiceId = invData.invoice_id;
            this.PurchasingInvoiceNo = invData.invoice_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchasing_invoice');
            this.Status = invData.status;
            
            window.$('#PurchasingInvoiceStatusModal').modal('show');
        },
        async saveClick(){
            var data = await purchasingInvoiceServices.getDetailInvoice(this.PurchasingInvoiceId);
            var itemGridRequestOrder = this.$globalfunc.objectToArrayConverter(data.purchasing_invoice_detail, "PurchasingInvoice-InvoiceItemDetailSave");

            //Status Delete Invoice_Number null
            var status = this.Status;
            var InvoiceNumber = data.invoice_number;
            if(status == "Delete") {
                InvoiceNumber = null;
            }

            const invoiceData = {
                status: this.Status,
                purchase_order_id: parseInt(data.purchase_order_id),
                invoice_number: InvoiceNumber,
                invoice_date: data.invoice_date,
                invoice_due_date: data.invoice_due_date == "" ? null : data.invoice_due_date,
                invoice_received_date: data.invoice_received_date,
                ppn_tax_percentage: data.ppn_tax_percentage,
                currency_id: data.currency_id,
                exchange_rate: data.exchange_rate,
                notes: data.notes,
                supplier_invoice_number: data.supplier_invoice_number,
                tax_invoice: data.tax_invoice,
                import_tax: parseFloat(data.import_tax),
                custom_clearance: parseFloat(data.custom_clearance),
                additional_tax: parseFloat(data.additional_tax),
                total: data.total,
                total_raw: data.total_raw,
                purchasing_invoice_detail: itemGridRequestOrder
            }

            const variables = {
                id : parseInt(this.PurchasingInvoiceId),
                data : invoiceData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    purchasingInvoiceServices.editQuery(variables).then(res => {
                        this.reload();
                        window.$('#PurchasingInvoiceStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>