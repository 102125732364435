<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Purchasing Invoice : {{InvoiceNumber}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Bukti</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoiceNumber" v-model="InvoiceNumber" class="font-weight-bold"/>
                            <label id="errorInvoiceNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nomor Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="SupplierInvoiceNumber" v-model="SupplierInvoiceNumber" class="font-weight-bold"/>
                            <label id="errorSupplierInvoiceNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PONumber" v-model="PONumber" class="font-weight-bold" readonly/>
                            <label id="errorPONumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                     <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content pb-3">
                            <date-picker
                                v-model="InvoiceDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorInvoiceDate" class="form-error pt-3" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Penerimaan Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content pb-3">
                            <date-picker
                                v-model="InvoiceReceivedDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorInvoiceReceivedDate" class="form-error pt-3" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Jatuh Tempo</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content pb-3">
                            <date-picker
                                v-model="DueDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDueDate" class="form-error pt-3" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pajak PPN (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="PPNTax" v-model="PPNTax" class="font-weight-bold" readonly/>
                            <label id="errorPPNTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mata Uang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Currency" v-model="Currency" class="font-weight-bold" readonly/>
                            <label id="errorCurrency" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kurs</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="ExchangeRate" v-model="ExchangeRate" class="font-weight-bold" readonly/>
                            <label id="errorExchangeRate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Vendor</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="VendorName" v-model="VendorName" class="font-weight-bold" readonly/>
                            <label id="errorVendorName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Faktur PPN</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="TaxInvoice" v-model="TaxInvoice" class="font-weight-bold" />
                            <label id="errorTaxInvoice" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <br>
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>
                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel"
                                :aggregate="[
                                    { field: 'vat', aggregate: 'sum' },
                                ]"
                            />
                            
                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="true"
                                        :pageable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :resizable="true"
                                        :cellClose="itemCellClose"
                                        >
                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'ordered_quantity'"
                                                    :title="'Ordered Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'invoiced_quantity'"
                                                    :title="'Invoiced Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'delivered_quantity'"
                                                    :title="'Delivered Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'remaining_quantity'"
                                                    :title="'Remaining Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                               
                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'vat_per_unit'"
                                                    :title="'Harga'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'vat'"
                                                    :title="'Total'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'> ` + this.Symbol + ` #=  kendo.toString(sum, 'n0') #</div>`"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    <CRow class="py-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pajak Import</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="ImportTax" v-model="ImportTax" class="font-weight-bold form-control input-number" @change="countTotalInvoice()"/>
                            <label id="errorImportTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="py-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Inklaring</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="CustomClearance" v-model="CustomClearance" class="font-weight-bold form-control input-number" @change="countTotalInvoice()"/>
                            <label id="errorCustomClearance" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="py-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">PPH</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="AdditionalTax" v-model="AdditionalTax" class="font-weight-bold form-control input-number" @change="countTotalInvoice()"/>
                            <label id="errorAdditionalTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="py-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalInvoiceRound" v-model="TotalInvoiceRound" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>
                    <br>    
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/purchasing-invoice')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import purchasingInvoiceServices from '../Script/PurcashingInvoiceServices';
import CurrencyService from '../../../MasterData/Currency/Script/CurrencyService.js';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'ProjectFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            var data = await purchasingInvoiceServices.getDetailPO(this.Id);
            var currencyData = await CurrencyService.getCurrencyById(data.currency_id);
            
            this.InvoiceNumber = 'Auto';
            this.SupplierInvoiceNumber = null;
            this.POId = data.purchase_order_id;
            this.PONumber = data.purchase_order_number;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.InvoiceDate = dateNow;
            this.InvoiceReceivedDate = dateNow;
            this.DueDate = null;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.PPNTax = data.ppn_tax_percentage;
            this.VendorId = data.vendor_id;
            this.VendorName = data.vendor_name;
            this.Currency = currencyData.currency_code;
            this.CurrencyId = data.currency_id;
            this.ExchangeRate= data.exchange_rate;
            
            this.Symbol = currencyData.currency_symbol

            this.TaxInvoice = '';
            this.Note = '';

            this.ImportTax = 0;
            this.CustomClearance = 0;
            this.AdditionalTax = 0;

            this.SaveType = 'Add';            
            document.getElementById("InvoiceNumber").readOnly = false

            //grid
            this.ItemGridData = purchasingInvoiceServices.invoiceItemDataChange(data.invoiceable_detail, this.FormType);

            this.countTotalInvoice();
        }
        else{
            var data = await purchasingInvoiceServices.getDetailInvoice(this.Id);
            var currencyData = await CurrencyService.getCurrencyById(data.currency_id);

            this.InvoiceNumber = data.invoice_number;
            this.SupplierInvoiceNumber = data.supplier_invoice_number;
            this.POId = data.purchase_order_id;
            this.PONumber = data.purchase_order_number;
            this.InvoiceDate= data.invoice_date;
            this.InvoiceReceivedDate= data.invoice_received_date;
            this.DueDate= data.invoice_due_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_purchasing_invoice');
            this.Status = data.status;
            this.PPNTax = data.ppn_tax_percentage;
            this.VendorId = data.supplier_id;
            this.VendorName = data.supplier_name;
            this.Currency = currencyData.currency_code;
            this.CurrencyId = data.currency_id;
            this.ExchangeRate = data.exchange_rate;
            
            this.Symbol = currencyData.currency_symbol

            this.TaxInvoice = data.tax_invoice;
            this.Note = data.notes;

            this.ImportTax = data.import_tax;
            this.CustomClearance = data.custom_clearance;
            this.AdditionalTax = data.additional_tax;

            this.SaveType = 'Edit';
            
            //grid
            this.ItemGridData = purchasingInvoiceServices.invoiceItemDataChange(data.purchasing_invoice_detail, this.FormType)

            this.countTotalInvoice();

            document.getElementById("InvoiceNumber").readOnly = true

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            //Detail
            Id: this.$route.params.id,

            InvoiceNumber: '',
            SupplierInvoiceNumber: '',
            POId: '',
            PONumber: '',
            InvoiceDate: '',
            InvoiceReceivedDate: '',
            DueDate: '',
            Status: '',
            StatusData: [],
            PPNTax: 0,
            VendorId: '',
            VendorName: '',
            Currency: '',
            CurrencyId: '',
            CurrencyData: [],
            ExchangeRate: 0,
            TotalInvoice: 0,
            TotalInvoiceRound: 0,

            TaxInvoice: '',
            Note: '',

            ImportTax: 0,
            CustomClearance: 0,
            AdditionalTax: 0,

            SaveType: '',
            Symbol: '',

            //grid
            ItemGridData: [],
            ItemSchemaModel: {
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                ordered_quantity: { type: "number", editable: false },
                invoiced_quantity: { type: "number", editable: false },
                delivered_quantity: { type: "number", editable: false },
                remaining_quantity: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:0 } },
                discount: { type: "number", editable: false },
                discount_amount: { type: "number", editable: false },
                price_per_unit: { type: "number", editable: false },
                price: { type: "number", editable: false },
                price_discount_per_unit: { type: "number", editable: false },
                price_discount: { type: "number", editable: false },
                vat_per_unit: { type: "number", editable: false },
                vat: { type: "number", editable: false },
            },
        }
    },
    methods: {
        async itemCellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            if(dataItem.quantity == null){
                dataItem.quantity = 0;
            }
            if(dataItem.quantity > dataItem.remaining_quantity){
                this.$swal("Error", "Quantity Invoice tidak boleh lebih besar dari Quantity Remaining", "error");
                dataItem.quantity = dataItem.remaining_quantity;
            }

            var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;
            
            var newData = purchasingInvoiceServices.invoiceItemDataChange(gridItem, "Edit")
            this.ItemGridData = newData;

            this.countTotalInvoice();
        },
        countTotalInvoice(){
            if(this.ImportTax == ''){
                this.ImportTax = 0;
            }
            if(this.CustomClearance == ''){
                this.CustomClearance = 0;
            }
            if(this.AdditionalTax == ''){
                this.AdditionalTax = 0;
            }

            var gridData = this.ItemGridData;
            var totalGross = 0;

            if(gridData.length > 0){
                gridData.forEach(e => {
                    totalGross = totalGross + (e.vat);
                });
            }

            totalGross = totalGross + parseFloat(this.ImportTax) + parseFloat(this.CustomClearance) + parseFloat(this.AdditionalTax);
            
            this.TotalInvoice = totalGross;
            this.TotalInvoiceRound = Math.round(totalGross);
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.InvoiceNumber == '' || this.InvoiceNumber == null){
                this.errorShow('errorInvoiceNumber');
            }
            if(this.SupplierInvoiceNumber == '' || this.SupplierInvoiceNumber == null){
                this.errorShow('errorSupplierInvoiceNumber');
            }
            if(this.InvoiceDate == '' || this.InvoiceDate == null){
                this.errorShow('errorInvoiceDate');
            }
            if(this.InvoiceReceivedDate == '' || this.InvoiceReceivedDate == null){
                this.errorShow('errorInvoiceReceivedDate');
            }
            if(toString(this.ImportTax) == '' || toString(this.ImportTax) == null){
                this.errorShow('errorImportTax');
            }
            if(toString(this.CustomClearance) == '' || toString(this.CustomClearance) == null){
                this.errorShow('errorCustomClearance');
            }
            if(toString(this.AdditionalTax) == '' || toString(this.AdditionalTax) == null){
                this.errorShow('errorAdditionalTax');
            }
            if(this.ItemGridData.length < 1){
                this.$swal("Error", "Item tidak boleh kosong", "error");
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //set grid
                var gridItemData = this.$refs.gridItem.kendoWidget().dataSource._data;
                var purchasingInvoiceDetail = this.$globalfunc.objectToArrayConverter(gridItemData, "PurchasingInvoice-InvoiceItemDetailSave");
                
                const invoiceData = {
                    status: this.Status,
                    purchase_order_id: parseInt(this.POId),
                    invoice_number: this.InvoiceNumber,
                    invoice_date: this.InvoiceDate,
                    invoice_due_date: this.DueDate == "" ? null : this.DueDate,
                    invoice_received_date: this.InvoiceReceivedDate,
                    ppn_tax_percentage: this.PPNTax,
                    currency_id: parseInt(this.CurrencyId),
                    exchange_rate: this.ExchangeRate,
                    notes: this.Note,
                    supplier_invoice_number: this.SupplierInvoiceNumber,
                    tax_invoice: this.TaxInvoice,
                    import_tax: parseFloat(this.ImportTax),
                    custom_clearance: parseFloat(this.CustomClearance),
                    additional_tax: parseFloat(this.AdditionalTax),
                    total: this.TotalInvoiceRound,
                    total_raw: this.TotalInvoice,
                    purchasing_invoice_detail: purchasingInvoiceDetail
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : invoiceData
                    }
                    
                    purchasingInvoiceServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/purchasing-invoice' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : invoiceData
                    }
                    
                    purchasingInvoiceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/purchasing-invoice'
                                }
                            }); 
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
</style>