<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Purchasing Invoice</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>&nbsp;
                    <!-- <button type="button"  class= "btn btn-primary" v-on:click="exportExcel">
                        <i class="fa fa-file-excel-o"></i> Excel
                    </button> -->
                </div>

                <purchasing-invoice-create-form ref="PurchasingInvoiceCreateForm"/>
                <purchasing-invoice-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick"/>
                <purchasing-invoice-status-form ref="PurchasingInvoiceStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import purchasingInvoiceCreateForm from './Component/PurchasingInvoiceCreateForm.vue';
import purchasingInvoiceGrid from './Grid/PurchasingInvoiceGrid.vue';
import purchasingInvoiceStatusForm from '../PurchasingInvoice/Component/PurchasingInvoiceStatusForm.vue';
import purchasingInvoiceServices from '../PurchasingInvoice/Script/PurcashingInvoiceServices.js'
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PurchasingInvoice',
    components: {
        'purchasing-invoice-create-form': purchasingInvoiceCreateForm,
        'purchasing-invoice-grid': purchasingInvoiceGrid,
        'purchasing-invoice-status-form': purchasingInvoiceStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Purchasing Invoice');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.PurchasingInvoiceCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Purchasing Invoice Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Purchasing Invoice Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await purchasingInvoiceServices.getDetailInvoice(id);

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    var itemGridRequestOrder = this.$globalfunc.objectToArrayConverter(data.purchasing_invoice_detail, "PurchasingInvoice-InvoiceItemDetailSave");

                    const invoiceData = {
                        status: 'Delete',
                        purchase_order_id: parseInt(data.purchase_order_id),
                        invoice_number: null,
                        invoice_date: data.invoice_date,
                        invoice_due_date: data.invoice_due_date == "" ? null : data.invoice_due_date,
                        invoice_received_date: data.invoice_received_date,
                        ppn_tax_percentage: data.ppn_tax_percentage,
                        currency_id: data.currency_id,
                        exchange_rate: data.exchange_rate,
                        notes: data.notes,
                        supplier_invoice_number: data.supplier_invoice_number,
                        tax_invoice: data.tax_invoice,
                        import_tax: parseFloat(data.import_tax),
                        custom_clearance: parseFloat(data.custom_clearance),
                        additional_tax: parseFloat(data.additional_tax),
                        total: data.total,
                        total_raw: data.total_raw,
                        purchasing_invoice_detail: itemGridRequestOrder
                    }
                    
                    const variables = {
                        id : parseInt(id),
                        data : invoiceData
                    }
                    
                    purchasingInvoiceServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.PurchasingInvoiceStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnPosting").on('click', function() {
                    vue.$refs.grid.changeStatus('Posting');
                });
                $("#btnPayment").on('click', function() {
                    vue.$refs.grid.changeStatus('Payment');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>